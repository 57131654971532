<template>
  <div>
    <div style="width: 1200px; margin: 0 auto; padding: 20px">
      <!--<div v-if="pagesName !== 'MyOrderInfo'">-->
      <!--    <el-breadcrumb separator="/" v-if="pagesName === 'BiddingPaymented'">-->
      <!--        <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item :to="{ path: '/home/Bidding' }">竞卖中心</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item>成功下单</el-breadcrumb-item>-->
      <!--    </el-breadcrumb>-->
      <!--    <el-breadcrumb separator="/" v-else>-->
      <!--        <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item :to="{ path: '/home/Mall' }">商品超市</el-breadcrumb-item>-->
      <!--        <el-breadcrumb-item>成功下单</el-breadcrumb-item>-->
      <!--    </el-breadcrumb>-->
      <div class="order-status">
        <div class="order-status-box">
          <div style="color: #56c075; font-size: 20px; margin-bottom: 20px">
            <i class="el-icon-success"></i>
            订单提交成功
          </div>
          <div class="box-item">
            <div class="title">订单号：</div>
            <div class="text">{{ orderInfo.orderSn }}</div>
          </div>
          <div class="box-item">
            <div class="title">订单状态：</div>
            <div class="text">{{ orderInfo.orderStatusText }}</div>
          </div>
          <div class="box-item">
            <div class="title">订单金额：</div>
            <div class="text" style="color: #ff0033">
              {{ orderInfo.orderPrice }}
            </div>

            元
          </div>
          <p
            v-if="orderStatus == 'DQS'"
            style="padding: 10px 20px; color: red; cursor: pointer"
            @click="toContractDetail"
          >
            订单提交成功，请及时完成合同签署
          </p>
        </div>
        <div class="order-status-box" style="padding-top: 66.67px">
          <div class="box-item" v-if="bidInfo.shipChannel">
            <div class="title">物流公司：</div>
            <div class="text">{{ bidInfo.shipChannel }}</div>
          </div>
          <div class="box-item" v-if="bidInfo.shipSn">
            <div class="title">物流号/车牌号：</div>
            <div class="text">{{ bidInfo.shipSn }}</div>
          </div>
        </div>
        <!--<p style="padding:10px 20px">注意：请及时联系卖家付款及交收货物，下单后<span-->
        <!--style="color:red;">2个小时内</span>联系卖家，如不及时联系，钢材超市有权取消订单</p>-->
      </div>
    </div>

    <div>
      <h1>订单流程</h1>
      <div
        v-if="['QXC', 'QXS'].indexOf(orderInfo.orderStatus) < 0"
        style="background: #fff; padding: 20px 20px 10px"
      >
        <template v-if="bidInfo.orderProcess === 'HK'">
          <el-steps :active="flowStep" align-center>
            <el-step title="确认订单"></el-step>
            <el-step title="合同签署"></el-step>
            <el-step title="供应商发货"></el-step>
            <el-step title="买家签收"></el-step>
            <el-step title="供应商开发票"></el-step>
            <el-step title="买家支付"></el-step>
            <el-step title="供应商收款"></el-step>
            <!-- <el-step title="流程结束"></el-step> -->
          </el-steps>
        </template>
        <template v-else>
          <el-steps :active="flowStep" align-center>
            <el-step title="确认订单"></el-step>
            <el-step title="合同签署"></el-step>
            <el-step title="买家支付"></el-step>
            <el-step title="供应商确认收款、发货、开发票"></el-step>
            <el-step title="买家签收"></el-step>
          </el-steps>
        </template>
      </div>
      <div
        v-else
        style="
          padding: 24px;
          background: #fff;
          color: #333333;
          font-size: 14px;
          line-height: 24px;
        "
      >
        {{
          orderInfo.orderStatu === "QXC" ? "订单取消待确认" : "订单已取消成功"
        }}
      </div>
    </div>
    <div>
      <h1>订单信息</h1>
      <div style="background: #fff; padding: 10px 20px 40px">
        <h3>{{ customer === "seller" ? "卖方" : "供应商" }}信息：</h3>
        <div v-for="(item, index) in brands" :key="index">
          <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              {{ customer === "seller" ? "卖方" : "供应商" }}：
            </div>
            <div style="flex: 0 0 500px">{{ item.brandName }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              联系人：
            </div>
            <div>{{ item.userName }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              电话：
            </div>
            <div>{{ item.phone }}</div>
          </div>
          <div class="border" style="display: flex; padding-bottom: 30px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              地址：
            </div>
            <div style="flex: 0 0 500px">
              {{ item.address }}
            </div>
          </div>
          <div class="dash"></div>
        </div>

        <h3>买方信息：</h3>
        <div>
          <div style="display: flex; margin-bottom: 10px; margin-top: 10px">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              买方：
            </div>
            <div style="flex: 0 0 500px">{{ orderInfo.clientInfoName }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px; align-items: center">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              联系人：
            </div>
            <div style="flex: 0 0 500px">{{ orderInfo.consignee }}</div>
          </div>
          <div style="display: flex; margin-bottom: 10px; align-items: center">
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              电话：
            </div>
            <div style="flex: 0 0 500px">{{ orderInfo.mobile }}</div>
          </div>
          <div
            class="border"
            style="display: flex; padding-bottom: 30px; align-items: center"
          >
            <div style="flex: 0 0 80px; text-align: right; color: #999">
              地址：
            </div>
            <div style="flex: 0 0 500px">{{ orderInfo.address }}</div>
          </div>
        </div>

        <template>
          <div class="info">
            <div class="info-left">
              <div class="name">{{ bidInfo.bidName }}</div>
              <div class="box1-wrap">
                <div class="box1">
                  <span>标的价格：</span>
                  <p class="price">{{ bidInfo.bidPrice }}元</p>
                </div>
                <div class="box1">
                  <span>税率：</span>
                  <p>{{ bidInfo.taxRate }}%</p>
                </div>
                <div class="box1">
                  <span>竞标开始时间：</span>
                  <p>{{ bidInfo.startTime }}</p>
                </div>
                <div class="box1">
                  <span>竞标结束时间：</span>
                  <p>{{ bidInfo.endTime }}</p>
                </div>
                <div class="box1">
                  <span>竞标交货时间：</span>
                  <p>{{ bidInfo.deliveryDate }}</p>
                </div>
                <div class="box1">
                  <span>合同信息：</span>
                  <p
                    style="color: blue; cursor: pointer"
                    @click="showContractMode"
                  >
                    查看合同模板
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- 采购产品 -->
          <div class="explain">
            <h3>采购产品</h3>
            <div class="explain-info">
              <el-table
                :data="bidInfo.bidProducts"
                :header-cell-style="{
                  background: '#f3f3f3',
                  'font-weight': 500,
                  color: '#666',
                  padding: '12px 0px',
                }"
                style="width: 100%"
              >
                <el-table-column type="index" label="序号" width="70">
                </el-table-column>
                <el-table-column
                  prop="productName"
                  label="产品名称"
                  width="200"
                >
                </el-table-column>
                <el-table-column prop="productBrand" label="产品品牌">
                </el-table-column>
                <el-table-column prop="productType" label="产品型号">
                </el-table-column>
                <el-table-column prop="specification" label="产品规格">
                </el-table-column>
                <el-table-column prop="number" label="采购量">
                </el-table-column>
                <el-table-column prop="unit" label="单位"> </el-table-column>
                <el-table-column prop="proPrice" label="总价（元）" width="120">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
        <div class="dash"></div>
        <div style="position: relative; height: 40px">
          <div style="position: absolute; right: 0; top: 20px; font-size: 18px">
            <span>应付总计：</span>
            <span style="color: #fc4500; font-size: 18px; margin-left: 10px">{{
              orderInfo.orderPrice
            }}</span>
            元
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 0 20px 20px; background: #fff">
      <div style="margin: 0 auto; width: 390px">
        <router-link
          :to="'/User/MyOrder?customer=' + this.customer"
          v-if="pagesName == 'MyOrderInfo'"
        >
          <el-button style="margin-right: 20px">返回订单列表</el-button>
        </router-link>
        <el-button @click="goHome" style="margin-right: 20px" v-else
          >返回首页</el-button
        >
        <el-button @click="printBtn" type="primary">打印</el-button>
        <el-button
          v-if="isShowSign"
          @click="toContractDetail"
          type="primary"
          >合同签署</el-button
        >
        <el-button
          v-if="isNoShowSign"
          @click="toContractDetail"
          type="primary"
          disabled
          >对方未签署</el-button
        >
        <el-button
          v-if="orderStatus == 'ZFC'"
          @click="submitOpr"
          type="primary"
          >确认收款</el-button
        >
      </div>
    </div>

    <div
      class="invoice-box"
      v-if="
        isHandleShow && customer == 'sellder' && orderInfo.orderStatus == 'DKP'
      "
    >
      <h2>开票信息</h2>
      <div class="invoice-content">
        <div class="invoice-item">
          <span class="title">单位名称：</span>
          {{ orderInfo.clientInfoName || "--" }}
        </div>
        <div class="invoice-item">
          <span class="title">开户行：</span>
          {{ invoiceInfo.accountBank || "--" }}
        </div>
        <div class="invoice-item">
          <span class="title">开户行账号：</span>
          {{ invoiceInfo.accountNo || "--" }}
        </div>
        <div class="invoice-item">
          <span class="title">纳税号：</span>
          {{ invoiceInfo.payTaxesNo || "--" }}
        </div>
      </div>
    </div>
    <div class="operate" v-if="oprVisiable">
      <h2 style="margin-bottom: 10px">操作</h2>
      <div class="content">
        <!-- 引入组件 -->
        <!-- <upload-files
          ref="uploadFiles"
          @upSuccess="upSuccess"
          :info="orderInfo"
        /> -->
      </div>
    </div>
   
      <div v-if="this.FkShow&&this.FkFhShow">
        <h2 style="margin-bottom: 10px" v-if="this.FkShow">付款安排</h2>
        <div style="background: #fff; padding: 10px">
          <el-table
            :data="payDeliverRateListData"
            :header-cell-style="{
              background: '#F5f5f5',
              height: ' 22px',
              'line-height': '22px',
              color: '#999',
            }"
            style="width: 100%"
          >
             <el-table-column align="center" prop="isFinish" label="付款状态">
          </el-table-column>
            <el-table-column prop="termIndex" label="期数" width="150">
              <!-- <template slot-scope="scope">
                      {{ scope.row.status | orderRecordStatus }}
                    </template> -->
            </el-table-column>
            <el-table-column prop="rate" label="金额比例(%)"> </el-table-column>
            <el-table-column prop="date" label="支付时间" width="150">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="this.FhShow&&this.FkFhShow">
        <h2 style="margin-bottom: 10px" v-if="this.FhShow">交货安排</h2>

        <div style="background: #fff; padding: 10px">
          <el-table
            :data="payDeliverRateListData"
            :header-cell-style="{
              background: '#F5f5f5',
              height: ' 22px',
              'line-height': '22px',
              color: '#999',
            }"
            style="width: 100%"
          >
                 <el-table-column align="center"  prop="isFinish" label="交货状态">
          </el-table-column>
            <el-table-column prop="termIndex" label="期数" width="150">
              <!-- <template slot-scope="scope">
                      {{ scope.row.status | orderRecordStatus }}
                    </template> -->
            </el-table-column>
            <el-table-column prop="rate" label="交货比例(%)"> </el-table-column>
            <el-table-column prop="date" label="交货时间" width="150">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div>
        <h2 style="margin-bottom: 10px">操作记录</h2>
        <div style="background: #fff; padding: 10px">
          <el-table
            :data="operatingRecordList"
            :header-cell-style="{
              background: '#F5f5f5',
              height: ' 22px',
              'line-height': '22px',
              color: '#999',
            }"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" width="100">
            </el-table-column>
            <el-table-column prop="status" label="状态" width="150">
              <template slot-scope="scope">
                {{ scope.row.status | orderRecordStatus }}
              </template>
            </el-table-column>
            <el-table-column prop="clientName" label="企业名称">
            </el-table-column>
            <el-table-column prop="dealUser" label="处理人" width="150">
            </el-table-column>
            <el-table-column prop="dealTime" label="处理时间" width="240">
            </el-table-column>
            <el-table-column label="文件" fixed="right" width="180">
              <template slot-scope="scope">
                <div class="operation-file">
                  <!--如果不是是支付，发货，开票，收货则不会有多个图片-->
                  <a
                    v-if="
                      scope.row.status != 'ZF' &&
                      scope.row.status != 'FH' &&
                      scope.row.status != 'KP' &&
                      scope.row.status != 'SH' &&
                      scope.row.fileName &&
                      scope.row.fileName != 'null'
                    "
                    :href="scope.row.fileUrl"
                    :title="scope.row.fileName"
                    target="_blank"
                    >{{ scope.row.fileName }}</a
                  >
                  <!--如果是支付，发货，开票，收货 ，则可能有多个图片显示-->
                  <a
                    v-if="
                      (scope.row.status == 'ZF' ||
                        scope.row.status == 'FH' ||
                        scope.row.status == 'KP' ||
                        scope.row.status == 'SH') &&
                      scope.row.fileName &&
                      scope.row.fileUrl &&
                      scope.row.fileName.indexOf('[') != -1
                    "
                    v-for="(item, i) in 20"
                    :href="JSON.parse(scope.row.fileUrl)[i]"
                    :title="JSON.parse(scope.row.fileName)[i]"
                    target="_blank"
                    ><p>{{ JSON.parse(scope.row.fileName)[i] }}</p></a
                  >
                  <!--兼容之前的订单操作消息-->
                  <a
                    v-if="
                      (scope.row.status == 'ZF' ||
                        scope.row.status == 'FH' ||
                        scope.row.status == 'KP' ||
                        scope.row.status == 'SH') &&
                      scope.row.fileName &&
                      scope.row.fileUrl &&
                      scope.row.fileName.indexOf('[') == -1
                    "
                    :href="scope.row.fileUrl"
                    :title="scope.row.fileName"
                    target="_blank"
                    >{{ scope.row.fileName }}</a
                  >

                  <a
                    v-if="
                      scope.row.status == 'FH' &&
                      invoiceData &&
                      invoiceData.shipChannel
                    "
                    href="$"
                    onclick="return false;"
                    ><p>物流公司：{{ invoiceData.shipChannel }}</p></a
                  >
                  <a
                    v-if="
                      scope.row.status == 'FH' &&
                      invoiceData &&
                      invoiceData.shipSn
                    "
                    href="$"
                    onclick="return false;"
                    ><p>物流号/车牌号：{{ invoiceData.shipSn }}</p></a
                  >
                  <a
                    v-if="
                      scope.row.status == 'KP' &&
                      invoiceData &&
                      invoiceData.invoiceSn
                    "
                    href="$"
                    onclick="return false;"
                    ><p>发票代码：{{ invoiceData.invoiceSn }}</p></a
                  >
                  <a
                    v-if="
                      scope.row.status == 'KP' &&
                      invoiceData &&
                      invoiceData.invoiceNo
                    "
                    href="$"
                    onclick="return false;"
                    ><p>发票号码：{{ invoiceData.invoiceNo }}</p></a
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 查看合同模板 -->
      <el-dialog
        title="合同模板"
        :visible.sync="contractDialogVisible"
        width="700px"
      >
        <div v-html="contractTextModel"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getOperatingRecord,
  changeOrderStatus,
  getBidOrderDetail,
  oprOrderStatus,
  uploadPath,
  getInvoiceInfo,
  identifyNote,
  vatInvoiceDistinguish,
} from "../../api";
import { showContractMode } from "../../../Home/api";
// import uploadFiles from "@/common/uploadFiles.vue";

export default {
  components: {
    // uploadFiles,
  },
  data() {
    var validatePrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入校验的票据金额"));
      } else if (
        this.noteInfo.notePrice != null &&
        Number(value) != this.noteInfo.notePrice
      ) {
        return callback(new Error("票据金额不一致"));
      } else {
        callback();
      }
    };
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入校验的票据号码"));
      } else if (
        this.noteInfo.noteNumber != null &&
        Number(value) != this.noteInfo.noteNumber
      ) {
        return callback(new Error("票据号码不一致"));
      } else {
        callback();
      }
    };
    return {
      FkFhShow: false,
      FkShow: false,
      FhShow: false,
      payDeliverRateListData: [],
      invoiceData: [],
      customer: "buyder",
      pagesName: "",
      isHandleShow: false,
      payCheck: false,
      contractId: "", //合同id
      contractContent: "", // 合同模板号
      contractTextModel: "", // 合同模板
      contractDialogVisible: false, // 合同弹窗
      orderStatus: "",
      orderId: undefined,
      uploadPath,
      fileList: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      param: {
        orderId: this.$route.query.orderid,
        status: "",
        fileUrl: "",
        fileName: "",
        shipSn: "",
        shipChannel: "",
        payId: "",
        invoiceSn: "",
        invoiceNo: "",
        fileUrl2: "",
        fileName2: "",
        fileNames: [],
        fileUrls: [],
        fileNames2: [],
        fileUrls2: [],
        fileNames3: [],
        fileUrls3: [],
        fileNames4: [],
        fileUrls4: [],
        fileNames5: [],
        fileUrls5: [],
        noteInfo: {},
        tInvoiceInfo: {},
      },
      invoiceData: {
        shipChannel: "",
        shipSn: "",
        invoiceSn: "",
        invoiceNo: "",
      },
        buyer: {

        },
      noteInfo: {
        payerName: "",
        payerAccount: "",
        payerBankName: "",
        notePrice: "",
        payeeName: "",
        payeeAccount: "",
        payeeBankName: "",
        noteNumber: "",
        notePriceConfirm: "",
        noteNumberConfirm: "",
      },
      orderInfo: {},
      brands: {},
      buyer: {},
      brands: {},
      tableData: [],
      operatingRecordList: [],
      imageUrl: "",
      imageName: "",
      logisticsCompany: "",
      logisticsNum: "",
      bidInfo: {}, // 标的信息
      flowStep: 0,
      invoiceInfo: {},
      rules: {
        notePriceConfirm: [
          { required: true, validator: validatePrice, trigger: "blur" },
        ],
        noteNumberConfirm: [
          { required: true, validator: validateNumber, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    console.log("this.flowStep", this.flowStep);
    this.orderId = this.$route.query.orderid;
    this.customer = this.$route.query.customer;
    this.pagesName = this.$route.name;
    if (this.$route.query.isHandleShow != undefined) {
      this.isHandleShow = this.$route.query.isHandleShow;
    }
    this.detail();
    this.getOperatingRecord();
  },
  computed: {
    // 判断是否显示签署按钮
    isShowSign() {
      let flag = false;
      let customer = this.$route.query.customer;
      let status = this.orderInfo.orderStatus === 'DQS' || this.orderInfo.orderStatus == 'DFWQ';
      if (customer === 'buyder' && status && this.orderInfo.myContractInfo == '1') flag = true;
      if (customer === 'sellder' && this.orderInfo.orderStatus === 'DQS') flag = true;
      return flag;
    },
    // 判断是否显示未签署按钮
    isNoShowSign() {
      let flag = false;
      let customer = this.$route.query.customer;
      let status = this.orderInfo.orderStatus === 'DQS' || this.orderInfo.orderStatus == 'DFWQ';
      if (customer === 'buyder' && status && this.orderInfo.myContractInfo == '0') flag = true;
      return flag;
    },
    oprVisiable: function () {
      if (this.isHandleShow) {
        if (this.orderInfo.orderStatus === "END") {
          return false;
        }
        if (this.customer === "buyder") {
          if (
            this.orderInfo.orderStatus === "DSH" ||
            this.orderInfo.orderStatus === "DZF"
          ) {
            return true;
          } else {
            return false;
          }
        } else if (this.customer === "sellder") {
          if (
            this.orderInfo.orderStatus === "DFH" ||
            this.orderInfo.orderStatus === "DKP" ||
            this.orderInfo.orderStatus === "QXC"
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
  },
  filters: {
    orderRecordStatus(value) {
      switch (value) {
        case "NEW":
          return "创建订单";
          break;
        case "FH":
          return "发货";
          break;
        case "SH":
          return "签收";
          break;
        case "KP":
          return "开发票";
          break;
        case "ZF":
          return "付款(商票)";
          break;
        case "SK":
          return "收款";
          break;
        case "QX":
          return "取消订单";
          break;
        case "QXS":
          return "取消成功";
          break;
        case "QXR":
          return "拒绝取消";
          break;
        case "YQS":
          return "合同签署";
          break;
        case "XQ":
          return "详情";
          break;
      }
    },
  },
  methods: {
    submitOpr() {
      this.$confirm('是否将订单修改为已收款', '开票确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const req = {
          orderId: this.$route.query.orderid,
          status: 'SK'
        };
        oprOrderStatus(req).then(res => {
          if (res.errno === 0) {
            this.$notify.success({
              title: '成功',
              message: '提交成功'
            });
            this.detail();
          }
        });
      });
    },
    upSuccess() {
      this.detail();
      this.getOperatingRecord();
    },

    //查询合同模板
    showContractMode() {
      if (this.contractTextModel == "" || this.contractTextModel == null) {
        showContractMode({ contractNo: this.contractContent }).then((res) => {
          this.contractTextModel = res.data.contractTextModel;
          this.contractDialogVisible = true;
        });
      } else {
        this.contractDialogVisible = true;
      }
    },
    FKchangeIsFinish(data) {
      if (data === "Y") {
        data = "已付款";
        return data;
      } else if (data == "N") {
        data = "未付款";
        return data;
      }
    },
    JHchangeIsFinish(data) {
      if (data === "Y") {
        data = "已交货";
        return data;
      } else if (data == "N") {
        data = "未交货";
        return data;
      }
    },
    changeTermIndex(data) {
      if (data === "DJ") {
        data = "质保";
        return data;
      } else if (data == "ZBJ") {
        data = "质保金";
        return data;
      } else if (data == "1") {
        data = "第1期";
        return data;
      } else if (data == "2") {
        data = "第2期";
        return data;
      } else if (data == "3") {
        data = "第3期";
        return data;
      } else if (data == "4") {
        data = "第4期";
        return data;
      } else if (data == "5") {
        data = "第5期";
        return data;
      } else if (data == "6") {
        data = "第6期";
        return data;
      } else if (data == "7") {
        data = "第7期";
        return data;
      } else if (data == "8") {
        data = "第8期";
        return data;
      } else if (data == "9") {
        data = "第9期";
        return data;
      } else if (data == "10") {
        data = "第10期";
        return data;
      } else if (data == "11") {
        data = "第11期";
        return data;
      } else if (data == "12") {
        data = "第12期";
        return data;
      } else if (data == "13") {
        data = "第13期";
        return data;
      } else if (data == "14") {
        data = "第14期";
        return data;
      } else if (data == "15") {
        data = "第15期";
        return data;
      } else if (data == "16") {
        data = "第16期";
        return data;
      } else if (data == "17") {
        data = "第17期";
        return data;
      } else if (data == "18") {
        data = "第18期";
        return data;
      } else if (data == "19") {
        data = "第19期";
        return data;
      } else if (data == "20") {
        data = "第20期";
        return data;
      } else if (data == "21") {
        data = "第21期";
        return data;
      } else if (data == "22") {
        data = "第22期";
        return data;
      } else if (data == "23") {
        data = "第23期";
        return data;
      } else if (data == "24") {
        data = "第24期";
        return data;
      }
    },
    orderStatusMsg(value) {
      if (value === "DFH") {
        return "待发货";
      } else if (value === "DSH") {
        return "待签收";
      } else if (value === "DKP") {
        return "待开发票";
      } else if (value === "DZF") {
        return "待支付";
      } else if (value === "ZFC") {
        return "支付待确认";
      } else if (value === "END") {
        return "交易完成";
      } else if (value === "QXC") {
        return "取消待确认";
      } else if (value === "QXS") {
        return "取消成功";
      } else if (value === "DQS") {
        return "合同未签署";
      } else if (value === "DFWQ") {
        return "对方未签署";
      }
    },
    detail() {
      getBidOrderDetail({
        id: this.orderId,
        customer: this.customer,
      }).then((res) => {
        if (res.errno === 0) {
          this.payDeliverRateListData = res.data.payDeliverRateList;
          this.contractId = res.data.contractInfo.id;
          this.contractContent = res.data.contractInfo.contractTextNo;
          this.orderStatus = res.data.biOrderVo.orderStatus;
          this.bidInfo = res.data.biOrderVo;
          this.orderInfo = {
            orderSn: res.data.biOrderVo.orderSn,
            id: res.data.contractInfo.orderId,
            consignee: res.data.biOrderVo.consignee,
            mobile: res.data.biOrderVo.mobile,
            address: res.data.biOrderVo.address,
            orderPrice: res.data.biOrderVo.orderPrice,
            clientInfoName: res.data.biOrderVo.userClientName,
            orderStatus: res.data.biOrderVo.orderStatus,
            orderStatusText: this.orderStatusMsg(
              res.data.biOrderVo.orderStatus
            ),
            myContractInfo:res.data.contractInfo.contractStatus,
            orderProcess: res.data.biOrderVo.orderProcess,
            payMode: res.data.biOrderVo.payMode,
            brandName: res.data.biOrderVo.clientName,
            userName: res.data.biOrderVo.supConsignee,
            phone: res.data.biOrderVo.supMobile,
            buyAddress: res.data.biOrderVo.supAddress,
          };
          if (res.data.biOrderVo && res.data.biOrderVo.orderStatus) {
            this.getFlowStep(
              res.data.biOrderVo.orderStatus,
              res.data.biOrderVo.orderProcess
            );
          }
          this.brands = [
            {
              brandName: res.data.biOrderVo.clientName,
              userName: res.data.biOrderVo.supConsignee,
              phone: res.data.biOrderVo.supMobile,
              address: res.data.biOrderVo.supAddress,
            },
          ];

          this.getInvoiceInfo(res.data.biOrderVo.userId);
          if (this.orderInfo != null && this.orderInfo.payMode == "CDHP") {
            this.payCheck = true;
          }
          /*if(res.data.noteInfo!=null&&res.data.noteInfo!=undefined&&res.data.noteInfo!=""){
            this.noteInfo=res.data.noteInfo
          }*/
          if (res.data.payDeliverRateList.length !== 0) {
            this.FkFhShow = true;
          }
          if (
            this.payDeliverRateListData.length !== 0 &&
            this.payDeliverRateListData[0].type == "FK"
          ) {
            this.payDeliverRateListData.map((item) => {
              item.termIndex = this.changeTermIndex(item.termIndex);
              item.isFinish = this.FKchangeIsFinish(item.isFinish);
            });
          }
          if (
            this.payDeliverRateListData.length !== 0 &&
            this.payDeliverRateListData[0].type == "JH"
          ) {
            this.payDeliverRateListData.map((item) => {
              item.termIndex = this.changeTermIndex(item.termIndex);
              item.isFinish = this.JHchangeIsFinish(item.isFinish);
            });
          }
        }
      });
    },

    getFlowStep(orderStatus, orderProcess) {
      ["DFWQ", "DQS"].indexOf(orderStatus) >= 0 && (this.flowStep = 1);
      if (orderProcess === "HK") {
        ["DFH"].indexOf(orderStatus) >= 0 &&
          ((this.flowStep = 2), (this.FhShow = true), (this.FkShow = false));
        ["DSH"].indexOf(orderStatus) >= 0 && (this.flowStep = 3);
        ["DKP"].indexOf(orderStatus) >= 0 && (this.flowStep = 4);
        ["DZF"].indexOf(orderStatus) >= 0 &&
          ((this.flowStep = 5), (this.FkShow = true), (this.FhShow = false));
        ["ZFC"].indexOf(orderStatus) >= 0 && (this.flowStep = 6);
        ["END"].indexOf(orderStatus) >= 0 && (this.flowStep = 7);
      } else {
        ["DZF"].indexOf(orderStatus) >= 0 &&
          ((this.flowStep = 2), (this.FkShow = true), (this.FhShow = false));
        ["DFH"].indexOf(orderStatus) >= 0 &&
          ((this.flowStep = 3), (this.FhShow = true), (this.FkShow = false));
        ["DSH"].indexOf(orderStatus) >= 0 && (this.flowStep = 4);
        ["END"].indexOf(orderStatus) >= 0 && (this.flowStep = 5);
      }
    },

    goHome() {
      this.$router.push({ path: "/home/HomePage" });
    },
    printBtn() {},
    getOperatingRecord() {
      getOperatingRecord({
        id: this.orderId,
        orderType: this.$route.query.orderType === "BI" ? 4 : 1,
      }).then((res) => {
        var str = res.data.splice(0, 1);
        if (str != null && str[0].fileName != null) {
          this.invoiceData = JSON.parse(str[0].fileName);
        }
        this.operatingRecordList = res.data || [];
      });
    },

    //合同签署
    toContractDetail() {
      this.$router.push({
        path: "/User/ContractDetail",
        query: {
          id: null,
          tciId: this.contractId,
          orderNum: this.orderInfo.orderSn,
          customer: this.$route.query.customer,
        },
      });
    },

    getInvoiceInfo(id) {
      getInvoiceInfo({
        userid: id,
      }).then((res) => {
        if (res.errno === 0) {
          this.invoiceInfo = res.data || {};
        }
      });
    },
  },
  created() {
    this.orderId = this.$route.query.orderid;
    this.customer = this.$route.query.customer;
    this.pagesName = this.$route.name;
    if (this.$route.query.isHandleShow != undefined) {
      this.isHandleShow = this.$route.query.isHandleShow;
    }
    this.detail();
    this.getOperatingRecord();
  },
};
</script>

<style lang="less" scoped>
.el-input--mini {
  width: 200px;
}
@deep: ~">>>";
.head_h3 {
  position: relative;
  padding: 16px 0 16px 20px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 16px;
  color: #333333;
  margin: 0;

  &::after {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    width: 4px;
    height: 20px;
    background: #008cf4;
    margin-top: -10px;
  }
}

.operate {
  .content {
    background: #fff;
    padding: 20px;

    .btn-box {
      display: flex;
      justify-content: center;
      .tit {
        padding-top: 10px;
        text-align: right;

        .required {
          color: #fe1846;
        }
        .image-name {
          color: #3a8ee6;
          text-decoration: underline;
        }
      }
      .reupload {
        padding-top: 10px;
        margin-left: 30px;
      }
      .uploader-btn {
        text-align: left;
        margin-left: 8px;
      }
      .del {
        padding-top: 10px;
        margin-left: 18px;
        cursor: pointer;
      }
    }
    .tips {
      text-align: center;
      color: #bbb;
      margin: 20px 0;
    }
    .input-box {
      margin-bottom: 30px;

      .input-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .tit {
          width: 122px;
          text-align: right;
          padding-right: 10px;

          .required {
            color: #fe1846;
          }
        }
      }
    }
    .footer {
      width: 100%;
      padding-top: 20px;
      box-sizing: border-box;
      // border-top: 1px dashed #ccc;
      text-align: center;

      .btn {
        width: 150px;
      }
    }
  }
}
.order-status {
  display: flex;
  // margin-top: 10px;
  background: #fff;
  height: 220px;
  padding: 20px;

  &-box {
    flex: 1;
    background: #f3ffff;
    padding: 20px;

    .box-item {
      display: flex;

      .title {
        text-align: right;
        color: #999;
        margin-bottom: 20px;
      }
    }
  }
}
.info {
  background: #fff;
  padding: 25px 22px 30px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info-left {
    .name {
      width: 360px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .box1-wrap {
      margin-top: 22px;

      .box1 {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        line-height: 1;

        span {
          color: #999999;
          font-size: 14px;
        }
        p {
          color: #333333;
          font-size: 14px;

          &.price {
            color: #ff4400;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
    }
  }
  .info-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .count-down-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 379px;
      height: 42px;
      background: #ff5a48;
      border-radius: 4px;

      .count-down-info {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #ffffff;

        .unit {
          color: #ffd44d;
          margin: 0 6px;
        }
      }
    }
    .addCart {
      width: 210px;
      height: 46px;
      text-align: center;
      line-height: 44px;
      border-radius: 4px;
      margin-top: 25px;
      cursor: pointer;
      font-size: 16px;
      color: #ffffff;
      box-sizing: border-box;

      &.click {
        color: #008cf4;
        border: 1px solid #008cf4;

        &:hover {
          background: #008cf4;
          color: #ffffff;
        }
      }
      &.readOnly {
        background-image: linear-gradient(to right, #d7d4d4, #c2c0c1);
      }
    }
  }
}
.explain {
  background: #fff;
  margin-top: 16px;

  .explain-info {
    padding: 24px;
  }
}
.invoice-box {
  .invoice-content {
    padding: 20px;
    background: #fff;

    .invoice-item {
      padding: 5px 0;
      font-size: 14px;

      .title {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }
}
/deep/.is-leaf {
  font-weight: 400;
}

.border {
  border-bottom: 1px solid #ebeef5;
}
.uplaod-box {
  width: 200px;
}
</style>
