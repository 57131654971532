var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { width: "1200px", margin: "0 auto", padding: "20px" } },
        [
          _c("div", { staticClass: "order-status" }, [
            _c("div", { staticClass: "order-status-box" }, [
              _vm._m(0),
              _c("div", { staticClass: "box-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单号：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.orderInfo.orderSn)),
                ]),
              ]),
              _c("div", { staticClass: "box-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单状态：")]),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.orderInfo.orderStatusText)),
                ]),
              ]),
              _c("div", { staticClass: "box-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("订单金额：")]),
                _c(
                  "div",
                  { staticClass: "text", staticStyle: { color: "#ff0033" } },
                  [_vm._v(" " + _vm._s(_vm.orderInfo.orderPrice) + " ")]
                ),
                _vm._v(" 元 "),
              ]),
              _vm.orderStatus == "DQS"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        padding: "10px 20px",
                        color: "red",
                        cursor: "pointer",
                      },
                      on: { click: _vm.toContractDetail },
                    },
                    [_vm._v(" 订单提交成功，请及时完成合同签署 ")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "order-status-box",
                staticStyle: { "padding-top": "66.67px" },
              },
              [
                _vm.bidInfo.shipChannel
                  ? _c("div", { staticClass: "box-item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("物流公司："),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.bidInfo.shipChannel)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.bidInfo.shipSn
                  ? _c("div", { staticClass: "box-item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("物流号/车牌号："),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.bidInfo.shipSn)),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      ),
      _c("div", [
        _c("h1", [_vm._v("订单流程")]),
        ["QXC", "QXS"].indexOf(_vm.orderInfo.orderStatus) < 0
          ? _c(
              "div",
              {
                staticStyle: { background: "#fff", padding: "20px 20px 10px" },
              },
              [
                _vm.bidInfo.orderProcess === "HK"
                  ? [
                      _c(
                        "el-steps",
                        { attrs: { active: _vm.flowStep, "align-center": "" } },
                        [
                          _c("el-step", { attrs: { title: "确认订单" } }),
                          _c("el-step", { attrs: { title: "合同签署" } }),
                          _c("el-step", { attrs: { title: "供应商发货" } }),
                          _c("el-step", { attrs: { title: "买家签收" } }),
                          _c("el-step", { attrs: { title: "供应商开发票" } }),
                          _c("el-step", { attrs: { title: "买家支付" } }),
                          _c("el-step", { attrs: { title: "供应商收款" } }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "el-steps",
                        { attrs: { active: _vm.flowStep, "align-center": "" } },
                        [
                          _c("el-step", { attrs: { title: "确认订单" } }),
                          _c("el-step", { attrs: { title: "合同签署" } }),
                          _c("el-step", { attrs: { title: "买家支付" } }),
                          _c("el-step", {
                            attrs: { title: "供应商确认收款、发货、开发票" },
                          }),
                          _c("el-step", { attrs: { title: "买家签收" } }),
                        ],
                        1
                      ),
                    ],
              ],
              2
            )
          : _c(
              "div",
              {
                staticStyle: {
                  padding: "24px",
                  background: "#fff",
                  color: "#333333",
                  "font-size": "14px",
                  "line-height": "24px",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.orderInfo.orderStatu === "QXC"
                        ? "订单取消待确认"
                        : "订单已取消成功"
                    ) +
                    " "
                ),
              ]
            ),
      ]),
      _c("div", [
        _c("h1", [_vm._v("订单信息")]),
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px 20px 40px" } },
          [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.customer === "seller" ? "卖方" : "供应商") + "信息："
              ),
            ]),
            _vm._l(_vm.brands, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "margin-bottom": "10px",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.customer === "seller" ? "卖方" : "供应商"
                            ) +
                            "： "
                        ),
                      ]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(_vm._s(item.brandName)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v(" 联系人： ")]
                    ),
                    _c("div", [_vm._v(_vm._s(item.userName))]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v(" 电话： ")]
                    ),
                    _c("div", [_vm._v(_vm._s(item.phone))]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "border",
                    staticStyle: { display: "flex", "padding-bottom": "30px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v(" 地址： ")]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(" " + _vm._s(item.address) + " "),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "dash" }),
              ])
            }),
            _c("h3", [_vm._v("买方信息：")]),
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v(" 买方： ")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.clientInfoName)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v(" 联系人： ")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.consignee)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v(" 电话： ")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.mobile)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "border",
                  staticStyle: {
                    display: "flex",
                    "padding-bottom": "30px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v(" 地址： ")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.address)),
                  ]),
                ]
              ),
            ]),
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info-left" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.bidInfo.bidName)),
                  ]),
                  _c("div", { staticClass: "box1-wrap" }, [
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("标的价格：")]),
                      _c("p", { staticClass: "price" }, [
                        _vm._v(_vm._s(_vm.bidInfo.bidPrice) + "元"),
                      ]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("税率：")]),
                      _c("p", [_vm._v(_vm._s(_vm.bidInfo.taxRate) + "%")]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("竞标开始时间：")]),
                      _c("p", [_vm._v(_vm._s(_vm.bidInfo.startTime))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("竞标结束时间：")]),
                      _c("p", [_vm._v(_vm._s(_vm.bidInfo.endTime))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("竞标交货时间：")]),
                      _c("p", [_vm._v(_vm._s(_vm.bidInfo.deliveryDate))]),
                    ]),
                    _c("div", { staticClass: "box1" }, [
                      _c("span", [_vm._v("合同信息：")]),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "blue", cursor: "pointer" },
                          on: { click: _vm.showContractMode },
                        },
                        [_vm._v(" 查看合同模板 ")]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "explain" }, [
                _c("h3", [_vm._v("采购产品")]),
                _c(
                  "div",
                  { staticClass: "explain-info" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.bidInfo.bidProducts,
                          "header-cell-style": {
                            background: "#f3f3f3",
                            "font-weight": 500,
                            color: "#666",
                            padding: "12px 0px",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", label: "序号", width: "70" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productName",
                            label: "产品名称",
                            width: "200",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "productBrand", label: "产品品牌" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "productType", label: "产品型号" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "specification", label: "产品规格" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "number", label: "采购量" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "unit", label: "单位" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "proPrice",
                            label: "总价（元）",
                            width: "120",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            _c("div", { staticClass: "dash" }),
            _c(
              "div",
              { staticStyle: { position: "relative", height: "40px" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "20px",
                      "font-size": "18px",
                    },
                  },
                  [
                    _c("span", [_vm._v("应付总计：")]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#fc4500",
                          "font-size": "18px",
                          "margin-left": "10px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.orderInfo.orderPrice))]
                    ),
                    _vm._v(" 元 "),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticStyle: { padding: "0 20px 20px", background: "#fff" } },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 auto", width: "390px" } },
            [
              _vm.pagesName == "MyOrderInfo"
                ? _c(
                    "router-link",
                    {
                      attrs: { to: "/User/MyOrder?customer=" + this.customer },
                    },
                    [
                      _c(
                        "el-button",
                        { staticStyle: { "margin-right": "20px" } },
                        [_vm._v("返回订单列表")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      on: { click: _vm.goHome },
                    },
                    [_vm._v("返回首页")]
                  ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.printBtn } },
                [_vm._v("打印")]
              ),
              _vm.isShowSign
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.toContractDetail },
                    },
                    [_vm._v("合同签署")]
                  )
                : _vm._e(),
              _vm.isNoShowSign
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: "" },
                      on: { click: _vm.toContractDetail },
                    },
                    [_vm._v("对方未签署")]
                  )
                : _vm._e(),
              _vm.orderStatus == "ZFC"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitOpr },
                    },
                    [_vm._v("确认收款")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.isHandleShow &&
      _vm.customer == "sellder" &&
      _vm.orderInfo.orderStatus == "DKP"
        ? _c("div", { staticClass: "invoice-box" }, [
            _c("h2", [_vm._v("开票信息")]),
            _c("div", { staticClass: "invoice-content" }, [
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("单位名称：")]),
                _vm._v(
                  " " + _vm._s(_vm.orderInfo.clientInfoName || "--") + " "
                ),
              ]),
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("开户行：")]),
                _vm._v(" " + _vm._s(_vm.invoiceInfo.accountBank || "--") + " "),
              ]),
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("开户行账号：")]),
                _vm._v(" " + _vm._s(_vm.invoiceInfo.accountNo || "--") + " "),
              ]),
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("纳税号：")]),
                _vm._v(" " + _vm._s(_vm.invoiceInfo.payTaxesNo || "--") + " "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.oprVisiable
        ? _c("div", { staticClass: "operate" }, [
            _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
              _vm._v("操作"),
            ]),
            _c("div", { staticClass: "content" }),
          ])
        : _vm._e(),
      this.FkShow && this.FkFhShow
        ? _c("div", [
            this.FkShow
              ? _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
                  _vm._v("付款安排"),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { background: "#fff", padding: "10px" } },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.payDeliverRateListData,
                      "header-cell-style": {
                        background: "#F5f5f5",
                        height: " 22px",
                        "line-height": "22px",
                        color: "#999",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "isFinish",
                        label: "付款状态",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "termIndex", label: "期数", width: "150" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "rate", label: "金额比例(%)" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "支付时间", width: "150" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      this.FhShow && this.FkFhShow
        ? _c("div", [
            this.FhShow
              ? _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
                  _vm._v("交货安排"),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { background: "#fff", padding: "10px" } },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.payDeliverRateListData,
                      "header-cell-style": {
                        background: "#F5f5f5",
                        height: " 22px",
                        "line-height": "22px",
                        color: "#999",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "isFinish",
                        label: "交货状态",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "termIndex", label: "期数", width: "150" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "rate", label: "交货比例(%)" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "交货时间", width: "150" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("div", [
        _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
          _vm._v("操作记录"),
        ]),
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px" } },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.operatingRecordList,
                  "header-cell-style": {
                    background: "#F5f5f5",
                    height: " 22px",
                    "line-height": "22px",
                    color: "#999",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "状态", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("orderRecordStatus")(scope.row.status)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "clientName", label: "企业名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "dealUser", label: "处理人", width: "150" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "dealTime", label: "处理时间", width: "240" },
                }),
                _c("el-table-column", {
                  attrs: { label: "文件", fixed: "right", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "operation-file" },
                            [
                              scope.row.status != "ZF" &&
                              scope.row.status != "FH" &&
                              scope.row.status != "KP" &&
                              scope.row.status != "SH" &&
                              scope.row.fileName &&
                              scope.row.fileName != "null"
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: scope.row.fileUrl,
                                        title: scope.row.fileName,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.fileName))]
                                  )
                                : _vm._e(),
                              _vm._l(20, function (item, i) {
                                return (scope.row.status == "ZF" ||
                                  scope.row.status == "FH" ||
                                  scope.row.status == "KP" ||
                                  scope.row.status == "SH") &&
                                  scope.row.fileName &&
                                  scope.row.fileUrl &&
                                  scope.row.fileName.indexOf("[") != -1
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: JSON.parse(scope.row.fileUrl)[
                                            i
                                          ],
                                          title: JSON.parse(scope.row.fileName)[
                                            i
                                          ],
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              JSON.parse(scope.row.fileName)[i]
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e()
                              }),
                              (scope.row.status == "ZF" ||
                                scope.row.status == "FH" ||
                                scope.row.status == "KP" ||
                                scope.row.status == "SH") &&
                              scope.row.fileName &&
                              scope.row.fileUrl &&
                              scope.row.fileName.indexOf("[") == -1
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: scope.row.fileUrl,
                                        title: scope.row.fileName,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.fileName))]
                                  )
                                : _vm._e(),
                              scope.row.status == "FH" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.shipChannel
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "物流公司：" +
                                            _vm._s(_vm.invoiceData.shipChannel)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.status == "FH" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.shipSn
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "物流号/车牌号：" +
                                            _vm._s(_vm.invoiceData.shipSn)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.status == "KP" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.invoiceSn
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "发票代码：" +
                                            _vm._s(_vm.invoiceData.invoiceSn)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.status == "KP" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.invoiceNo
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "发票号码：" +
                                            _vm._s(_vm.invoiceData.invoiceNo)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "合同模板",
            visible: _vm.contractDialogVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.contractDialogVisible = $event
            },
          },
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.contractTextModel) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          color: "#56c075",
          "font-size": "20px",
          "margin-bottom": "20px",
        },
      },
      [_c("i", { staticClass: "el-icon-success" }), _vm._v(" 订单提交成功 ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }